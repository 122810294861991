const MastereventList = ["2x2x2", "3x3x3", "4x4x4", "5x5x5", "6x6x6", "7x7x7", "3x3x3 Blindfolded", "3x3x3 Fewest Moves", "3x3x3 One-Handed", "Clock", "Megaminx", "Pyraminx", "Skewb", "Square-1", "4x4x4 Blindfolded", "5x5x5 Blindfolded", "3x3x3 Multi-Blind"];

const saveSelectedEventsToString = (EventToParticipate) => {
    let selectedEvents = "";
    for (const entry of MastereventList) {
        let key = entry;
        let value = EventToParticipate[entry];
        if (value == true) {
            selectedEvents += key + ",";
        }
    }
    // remove the last comma if the last char is comma
    if (selectedEvents.slice(-1) === ",") {
        selectedEvents = selectedEvents.slice(0, -1);
    }
    return selectedEvents;
}
const saveSelectedEventsPriceToString = (eventPrice, EventToParticipate) => {
    let selectedEventsPrice = "";
    for (const entry of MastereventList) {
        let key = entry;
        let value = EventToParticipate[entry];
        if (value == true) {
            selectedEventsPrice += key + ":" + eventPrice[entry] + ",";
        }
    }
    // remove the last comma if the last char is comma
    if (selectedEventsPrice.slice(-1) === ",") {
        selectedEventsPrice = selectedEventsPrice.slice(0, -1);
    }


    return selectedEventsPrice;
}

const participantInfoColumns = [
    "RunningNumber",
    "UserName",
    "gender",
    "dob",
    "country",
    "email",
    "phoneNumber",
    "LinkedWCAEmail",
    "WCAID",
    "Register_date",
    "attended",
    "checkInDateTime",
    "events",
    "remark",
    "CheckInRemark",
    "Outstanding",
    "GuestNumber",
    "tshirtSize",
    "Base_charges",
    "EventCharge",
    "Admin_charges",
    "Guest_charges",
    "totalCharges",
    "totalPaid",
    "paid",
    "paymentMethod",
    "PaymentID",
    "StripePaymentID",
    "approved",
    "PaperCertificate",
    "pastPaymentHistory",
    "compLocation",
    "timeSlot"
    ];

const generateParticipantCSV =async (participants) => {
    let csv = participantInfoColumns.join(",") + "\n";
    for (const participant of participants) {
        if (participant.approved === false){
            continue;
        }
        let row = [];
        for (const column of participantInfoColumns) {

            if (participant.hasOwnProperty(column)){
                row.push(`"${participant[column]}"`);
            }
            else{
                row.push("");
            }
        }
        csv += row.join(",") + "\n";
    }
    return csv;
}

const generateCSV = async (rows,columnName) => {
    let csv = columnName.join(",") + "\n";
    for (const row of rows) {
        let rowArray = [];
        for (const column of columnName) {
            if (row.hasOwnProperty(column)){
                rowArray.push(`"${row[column]}"`);
            }
            else{
                rowArray.push("");
            }
        }
        csv += rowArray.join(",") + "\n";
    }
    return csv;
}
const UpdateType = {
    "Participant": "Participant",
    "staff": "staff",
    "merchant": "merchant",
    "MYtshirt": "MYtshirt"
}
export {generateCSV,generateParticipantCSV, MastereventList, saveSelectedEventsToString, saveSelectedEventsPriceToString,UpdateType };