import React, { useState,useEffect } from "react";
import ReactDOM from "react-dom";
import "./Login.css";
import {Amplify,Auth} from 'aws-amplify';
import LoadingOverlay from "../../Component/loadingPage/LoadingPage";


function LoginPage() {

  // React States
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
    Amplify.configure({
        Auth: {
            userPoolId: process.env.REACT_APP_ADMINUSERPOOL_ID, //UserPool ID
            region:  process.env.REACT_APP_AWSREGION,
            userPoolWebClientId: process.env.REACT_APP_ADMINUSERPOOL_WEBCLIENT //WebClientId
        }
    });

  const GetloginStatus =async ()=>{
    try {
      const AuthInfo = await Auth.currentAuthenticatedUser();
     if (AuthInfo.username === undefined) {
      setIsSubmitted(false);
     }
     else{
      setIsSubmitted(true);
     }
  } catch {
    setIsSubmitted(false);

  }
  }
  useEffect(() => {
    GetloginStatus();
  },[isSubmitted]);

  const errors = {
    uname: "invalid username",
    pass: "invalid password",
    wrongAccount:"Invalid Account Information !"
  };

  function refreshPage() {
    window.location.reload(false);
  }

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();
    setIsLoading(true);
    var { uname, pass } = document.forms[0];
    
    Auth.signIn(uname.value, pass.value).then((result) => {
      setIsSubmitted(true);
      //setIsLoading(false);
      refreshPage() ;
     }).catch((err) => {
      setErrorMessages({name:"uname",message:"Invalid Account Information !"})

     })
     setIsLoading(false);
  
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  const renderForm = (
    <div className="form">
 
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Email </label>
          <input type="text" name="uname" required />
          {renderErrorMessage("uname")}
        </div>
        <div className="input-container">
          <label>Password </label>
          <input type="password" name="pass" required />
          {renderErrorMessage("pass")}
          {renderErrorMessage("wrongAccount")}
        </div>
        <div className="button-container">
          <input type="submit" />
        </div>
      </form>
    </div>
  );
  return (
    <div className="app">
       <LoadingOverlay isLoading={isLoading}/>
      <div className="login-form">
        <div className="title">Sign In</div>
        {isSubmitted ? <div>User logged in successfully</div> : <div>{renderForm}</div>}
      </div>
     
    </div>
  );
}

export default LoginPage;