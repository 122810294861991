import { retry } from '@aws-amplify/core';
import { stepConnectorClasses } from '@mui/material';
import axios from 'axios';
import React from "react";
import { Amplify, Auth } from 'aws-amplify';

Amplify.configure({
    Auth: {
        userPoolId: process.env.REACT_APP_ADMINUSERPOOL_ID, //UserPool ID
        region:  process.env.REACT_APP_AWSREGION,
        userPoolWebClientId: process.env.REACT_APP_ADMINUSERPOOL_WEBCLIENT //WebClientId
    }
});

  // get user name from cognito
    const getUserName = async () => {
        const AuthInfo = await Auth.currentAuthenticatedUser();
        return AuthInfo.username;
    };

  
const getEventList =async (isActive) => {
    
const baseURL = process.env.REACT_APP_AWSPUBLICAPI + "GetCompetitionList";
let APIReturn;
 await axios
.post(baseURL, {requestData:{ActiveStatus: isActive}}).then((response) => {
    APIReturn = response;

  });
  return APIReturn.data;
};

const getEventDetails =async (eventid,userEmail) =>{
    const baseURL = process.env.REACT_APP_AWSPUBLICAPI + "GetCompetitionDetails";
let APIReturn;
 await axios
.post(baseURL, {requestData:{CompetitionID: eventid,email:userEmail}}).then((response) => {
    APIReturn = response;
  });
  return APIReturn.data;
};

    const  getParticipantList =async (competition_ID,adminToken) =>{
        const baseURL = process.env.REACT_APP_AWSADMINAPI + "GetParticipantList";
        let APIReturn;
         await axios
        .post(baseURL, {requestData:{CompetitionID: competition_ID}},{headers: {"Authorization": adminToken}}).then((response) => {
            APIReturn =  response;
          });

           return await APIReturn.data;
        };

const  getStaffList =async (competition_ID,adminToken,tableType) =>{
    const baseURL = process.env.REACT_APP_AWSADMINAPI + "GetStaffList";
    let APIReturn;
    await axios
        .post(baseURL, {requestData:{competition_ID: competition_ID,tableType}},{headers: {"Authorization": adminToken}}).then((response) => {
            APIReturn =  response;
        });

    return await APIReturn.data;
};

const  getMerchantFormList =async (adminToken) =>{
    const baseURL = process.env.REACT_APP_AWSADMINAPI + "GetMerchantFormList";
    let APIReturn;
    await axios
        .post(baseURL, null,{headers: {"Authorization": adminToken}}).then((response) => {
            APIReturn =  response;
        });

    return await APIReturn.data;
};

    const  registerCompetitor =async (competition_ID,events,GuestNumber,PaperCertificate,userToken) =>{
        const baseURL = process.env.REACT_APP_AWSUSERAPI + "registerCompetition";
        let APIReturn;
            await axios
        .post(baseURL, {requestData:{competition_ID: competition_ID,events:events,GuestNumber:GuestNumber,PaperCertificate:PaperCertificate}},{headers: {"Authorization": userToken}}).then((response) => {
            APIReturn =  response;
            });

            return await APIReturn.data;
        };

    const  makeCompPayment =async (competition_ID,email,userToken) =>{
        const baseURL = process.env.REACT_APP_AWSUSERAPI + "MakeCompPayment";
        let APIReturn;
            await axios
        .post(baseURL, {requestData:{CompetitionID: competition_ID,email:email}},{headers: {"Authorization": userToken}}).then((response) => {
            APIReturn =  response;
            });

            return await APIReturn.data;
        };
// call post api  updateParticipantStatus
const updateParticipantStatus =async (competition_ID,participantID,adminToken,isApprove,isDeleted,isRefund) =>{
    const baseURL = process.env.REACT_APP_AWSADMINAPI + "updateParticipantStatus";
        let APIReturn;
            await axios
        .post(baseURL, {requestData:{CompetitionID: competition_ID,participantID:participantID,approved:isApprove,deleted:isDeleted,refund:isRefund}},{headers: {"Authorization": adminToken}}).then((response) => {
            APIReturn =  response;
        });
        return await APIReturn;
    };
export const RefundAndDeleteParticipant =async (participantID,adminToken,isApprove,isDeleted,isRefund,refundAmount) =>{
    const baseURL = process.env.REACT_APP_AWSADMINAPI + "updateParticipantStatus";
    let APIReturn;
    await axios
        .post(baseURL, {requestData:{participantID:participantID,approved:isApprove,deleted:isDeleted,refund:isRefund,refundAmount:refundAmount}},{headers: {"Authorization": adminToken}}).then((response) => {
            APIReturn =  response;
        });
    console.log(APIReturn)
    return await APIReturn;
};

    //post api call to CreateCompetition 
    const createCompetition =async (competitionData,adminToken) =>{
        try {
            const baseURL = process.env.REACT_APP_AWSADMINAPI + "CreateCompetition";
            let APIReturn;
            const userName = await getUserName();
            competitionData.created_id = userName;
            await axios
            .post(baseURL, {requestData:competitionData},{headers: {"Authorization": adminToken}}).then((response) => {
                APIReturn =  response;
    
            });
            return await APIReturn;
        }
        catch (error) {
            return error;
        } 
         

    };

    //post api call to UpdateCompetitionInfo
    const updateCompetitionInfo =async (competitionData,adminToken) =>{
        try {
            const baseURL = process.env.REACT_APP_AWSADMINAPI + "UpdateCompetitionInfo";
            let APIReturn;
            const userName = await getUserName();
            competitionData.created_id = userName;
            await axios
            .post(baseURL, {requestData:competitionData},{headers: {"Authorization": adminToken}}).then((response) => {
                APIReturn =  response;
    
            });
            return await APIReturn;
        }
        catch (error) {
            return error;
        } 
    }

    const getEventDetailsGeneral =async (eventid) =>{
        const baseURL = process.env.REACT_APP_AWSPUBLICAPI + "GetCompetitionDetails";
    let APIReturn;
     await axios
    .post(baseURL, {requestData:{CompetitionID: eventid}}).then((response) => {
        APIReturn = response;
      });
      return APIReturn.data;
    };
const getEventDetailsAdmin =async (eventid,adminToken) =>{
    const baseURL = process.env.REACT_APP_AWSADMINAPI + "GetCompetitionDetailsAdmin";
    let APIReturn;
    await axios
        .post(baseURL, {requestData:{CompetitionID: eventid}},{headers: {"Authorization": adminToken}}).then((response) => {
            APIReturn = response;
        });
    return APIReturn.data;
};
const getParticipantInfo = async (participantID, adminToken) => {
    const baseURL = process.env.REACT_APP_AWSADMINAPI + "GetParticipantInfo";
    let APIReturn;
    await axios
        .post(baseURL, { requestData: { ParticipantID: participantID } }, { headers: { "Authorization": adminToken } }).then((response) => {
            APIReturn = response;
        });
    return await APIReturn.data;
};

const updateParticipantInfo = async (participantData, adminToken) => {
        const baseURL = process.env.REACT_APP_AWSADMINAPI + "UpdateParticipantInfo";
    let APIReturn;
    try {
        await axios
            .post(baseURL, { requestData: participantData }, { headers: { "Authorization": adminToken } }).then((response) => {
                APIReturn = response;

            });
        return await APIReturn.data;
    }
    catch (error) {
        return {data:error};
    }
}

const generateWCAFile = async (ID, adminToken) => {
    const baseURL = process.env.REACT_APP_AWSADMINAPI + "generateWCAFile";
    let APIReturn;
    try {
        await axios
            .post(baseURL, { requestData: { CompetitionID: ID } }, { headers: { "Authorization": adminToken } },{ responseType: 'arraybuffer' }, {responseEncoding: 'binary'}).then((response) => {
                APIReturn = response;

            });
        return await APIReturn;
    }
    catch (error) {
        return { data: error };
    }

}

const ResendCompEmail = async (participantID, adminToken) => {
    const baseURL = process.env.REACT_APP_AWSADMINAPI + "ResendCompTicket";
    let APIReturn;
    try {
        await axios
            .post(baseURL, { requestData: {participantID:participantID} }, { headers: { "Authorization": adminToken } }).then((response) => {
                APIReturn = response;

            });
        return await APIReturn.data;
    }
    catch (error) {
        return {data:error};
    }
}

const UpdateParticipantAttendance =async (dataID,competitionID,attended,type,adminToken) =>{
    const baseURL = process.env.REACT_APP_AWSADMINAPI + "UpdateAttendanceAdmin";
    let APIReturn;
    try {
        await axios
            .post(baseURL, { requestData: {dataID,attended,type} },{ headers: { "Authorization": adminToken }}).then((response) => {
                APIReturn = response;
            });
        //return false if status code not 200
        if (APIReturn.status !== 200) {
            return false;
        }
        return await APIReturn.data;
    }
    catch (error) {
        return "There is error when getting data, please try again later"
    }
}

export {getMerchantFormList,
    ResendCompEmail,
    getStaffList,
    getEventList,
    getEventDetails,
    getParticipantList,
    registerCompetitor,
    makeCompPayment,
    updateParticipantStatus,
    createCompetition,
    updateCompetitionInfo,
    getEventDetailsGeneral,
    getParticipantInfo,
    updateParticipantInfo,
    generateWCAFile,
    getEventDetailsAdmin,
    UpdateParticipantAttendance};